import HERO_IMG from "../../assets/images/elsnappo-final.png";
// import BG_DESKTOP from "../../assets/images/bg.desktop.png";
// import BG_MOBILE from "../../assets/images/bg.mobile.png";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import { RoutePattern } from "../../routes/RoutePattern";
import { CriOS, iOS } from "../../util";

export default function Showcase() {
  return (
    <div
      id={RoutePattern.HOME}
      className={`
      ${CriOS ? "h-[88vh]" : iOS ? "h-[91vh]" : "h-[93vh]"} 
      overflow-hidden relative md:h-screen flex flex-col justify-center items-center w-full`}
    >
      <div className="w-full h-full flex">
        <img
          onClick={() => {
            window.open("https://elsnappo.lnk.to/LOAZ", "_blank");
          }}
          className="cursor-pointer object-cover object-top w-[90vw] lg:w-[35vw] m-auto"
          src={HERO_IMG}
          alt="desktop"
        />
      </div>
      <div className="absolute bottom-[7%] md:bottom-[2%] left-1/2 transform -translate-x-1/2">
        <SocialIcons />
      </div>
    </div>
  );
}
